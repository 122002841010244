.wrapper {
    width: 100%;
}

.result {
    margin: 10px 0;
    width: 100%;
    height: 50px;
}

.button {
    margin: 10px auto 0;
    padding: 5px 10px;
    color: #fff;
    background-color: red;
    border-radius: 5px;
    width: 150px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}