.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3000;
  background-color: #eee;
}

.buttons {
  display: grid;
  grid-template-columns: 170px 1fr 70px;
}

.sscc {
  display: grid;
  grid-template-columns: 1fr 50px;
  gap: 10px;
  margin-bottom: 5px;
  border: 1px solid #3f3f3f;
  border-radius: 5px;
}

.codesOut {
  font-size: 16px;
  word-break: break-all;
  padding-top: 3px;
  text-align: center;
  height: 30px;
}

.codes {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1500;
  width: 300px;
  height: 400px;
  border-radius: 5px;
  background-color: #b9b9b9;
  overflow-y: auto;
  scrollbar-color: #102a43 #829ab1;
  scrollbar-width: thin;
}

.code {
  font-size: 11px;
  word-break: break-all;
  padding-top: 3px;
  padding-left: 30px;
  /* text-align: center; */
}

.codes::-webkit-scrollbar, .tovars::-webkit-scrollbar {
  width: 3px;
}

.codes::-webkit-scrollbar-track, .tovars::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.codes::-webkit-scrollbar-thumb, .tovars::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.counter {
  align-self: center;
  text-align: center;
}