.authSwitchButton {
  display: block;
  margin: 5px auto;
  background-color: #1eb81e;
  border: none;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 0 3px 1px greenyellow;
  border-radius: 5px;
}

.authSwitchButton:disabled {
  background-color: #ccc;
}

.authButton {
  display: block;
  margin: 15px auto;
  background-color: #276cd4;
  border: none;
  padding: 7px 15px;
  color: #fff;
  cursor: pointer;
  /* box-shadow: 0 0 3px 1px rgb(91, 189, 255); */
  border-radius: 5px;
  font-size: 20px;
}

.scanButton {
  display: block;
  margin: 10px auto 0;
  padding: 5px 10px;
  color: #fff;
  background-color: #fa4646;
  border-radius: 5px;
  width: 150px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.editButton {
  margin: 10px auto;
  padding: 5px;
  cursor: pointer;
  display: block;
  color: #333;
  background-color: yellow;
  border: 1px solid #333;
  border-radius: 5px;
  width: 150px;
}

.closeButton {
  margin: 10px auto;
  padding: 5px;
  cursor: pointer;
  display: block;
  color: red;
  font-weight: bold;
  background-color: transparent;
  width: 50px;
  border: none;
}

.deleteButton {
  margin: 5px auto;
  padding: 5px;
  cursor: pointer;
  display: block;
  color: #0059ff;
  font-weight: bold;
  background-color: transparent;
  width: 50px;
  border: none;
}

.errorButton {
  margin: 10px auto;
  padding: 5px;
  cursor: pointer;
  display: block;
  background-color: red;
  color: #fff;
  font-weight: bold;
  width: 150px;
  border: none;
}