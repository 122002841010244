.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  z-index: 500;
  background-color: #c8c990;
  margin: auto;
  justify-items: center;
  align-items: center;
}

.menuButton {
  background-color: #333;
  color: yellow;
  border: 1px solid yellow;
  border-radius: 5px;
  min-width: 80px;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
}

.menuButtonActive {
  background-color: yellow;
  color: #333;
  border: 1px solid #333;
  border-radius: 5px;
  box-shadow: 0 0 3px 1px rgb(119, 119, 1);
  min-width: 80px;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.exitButton {
  background-color: #888;
  color: white;
  border: 1px solid red;
  border-radius: 5px;
  min-width: 65px;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
}

.unpackButton {
  background-color: rgb(0, 59, 252);
  color: white;
  border: 1px solid red;
  border-radius: 5px;
  min-width: 65px;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 320px) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
}