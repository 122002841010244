.wrapper {

}

.message {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
}

.button {
    background-color: #ccc;
    padding: 5px 10px;
    margin: auto;
    box-shadow: 0 0 0 3px black;
    cursor: pointer;
}