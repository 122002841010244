.wrapper {
  padding-top: 55px;
  height: 100vh;
  overflow-y: auto;
  scrollbar-color: #102A43 #829AB1;
  scrollbar-width: thin;
}

.documentList {}

.wrapper::-webkit-scrollbar {
  width: 3px;
}

.wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.element, .elementEdited, .elementHeader {
  padding: 10px;
  background-color: #eee;
  z-index: 100;
  margin: 5px;
  border: 1px solid #d8d801;
  border-radius: 5px;
  display: grid;
  gap: 10px;
  grid-template-columns: 200px 1fr;
  /* &:hover {
    box-shadow: $ {
      props=>(props.header ? '': '0 0 5px green')
    }
    ;
  } */
}

.element, .elementEdited {
  cursor: pointer;
}

.elementEdited {
  background-color: #9bdd88;
}

.elementHeader {
  text-align: center;
  font-style: italic;
}

.elementNumberDate {
  text-align: center;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(2, 1fr);
}

.elementData {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 400px;
}

.elementPartner {
  font-weight: bold;
}

.elementSumm {
  text-align: center;
  /* color: #d8d801; */
}

@media (max-width: 992px) {
  .element, .elementEdited, .elementHeader {
    grid-template-columns: 100px 1fr;
  }
  .elementNumberDate {
    grid-template-columns: 1fr;
  }
  .elementData {
    grid-template-columns: 1fr 380px;
  }
}

@media (max-width: 768px) {
  /* .element, .elementHeader {
    grid-template-columns: 100px 1fr 190px;
  } */
  .elementData {
    grid-template-columns: 1fr 190px;
  }
}

@media (max-width: 576px) {
  /* .element, .elementHeader {
    grid-template-columns: 100px 1fr;
    gap: 5px;
  } */
  /* .elementNumberDate {
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
  } */
  .elementPartner {
    text-align: center;
  }
  .elementData {
    grid-template-columns: 1fr;
  }
}