.wrapper {
  margin: 10px 0 0 40px;
}

.wrapperCheckbox {
  margin: 5px;
}

.labelCheckbox {
  color: green;
}

.label {
  display: block;
  /* margin-left: 40px; */
  color: blue;
}

.inputCheckbox {}

.input {
  display: block;
  /* margin-left: 40px; */
  font-size: 18px;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}