.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 320px;
  min-height: 450px;
  background-color: #ccc;
  box-shadow: 0 0 5px 1px red;
  z-index: 100;
  border-radius: 2px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
}

.inputs {
  margin-top: 60px;
  margin-bottom: 40px;
}