.wrapper {
    width: 100%;
    position: relative;
}

.info {
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 100px;
}

.result {
    margin: 10px 0;
    width: 100%;
    height: 30px;
    overflow: hidden;
}