.wrapper {
    width: 320px;
    margin: auto;
}

.tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 5px 0;
}

.tab {
    padding: 5px 10px;
    border: 1px solid green;
    border-radius: 5px;
    text-align: center;
    color: green;
    text-transform: uppercase;
    cursor: pointer;
}

.reader {
    /* margin-bottom: 10px; */
}

.dataList {

}

.dataItem {
    border: 1px solid black;
    width: 100%;
}